/**
 * Created by jerry on 2020/03/23.
 * 产品经手人设置api
 */
import * as API from '@/api/index'

export default {
    // 获取产品经手人设置列表
    getProductSaleHandler: params => {
        return API.POST('api/productSaleHandler/all', params)
    },
    // 创建产品经手人设置
    createProductSaleHandler: params => {
        return API.POST('api/productSaleHandler/create', params)
    },
    // 更新产品经手人设置
    updateProductSaleHandler: params => {
        return API.POST('api/productSaleHandler/update', params)
    },
    // 获取职务列表
    getJobTypeAll: params => {
        return API.POST('api/jobtype/all', params)
    },
    // 获取组织架构列表
    getEntityList: params => {
        return API.POST('api/entity/list', params)
    },
    // 调整经手人设置顺序
    moveProductSaleHandler: params => {
        return API.POST('api/productSaleHandler/move', params)
    },
}