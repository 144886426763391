<template>
  <div class="content_body Handler" v-loading="loading" style="height: calc(100% - 30px)">
    <el-tabs tab-position="left" v-model="currentPage" class="tab_pane" @tab-click="selectCardType" style="height: 100%">
      <el-tab-pane label="产品销售经手人" name="1" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="产品销售经手人">
                  <el-input @clear="handleSearch" v-model="Name" clearable placeholder="请输入产品销售经手人名称"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select @change="handleSearch" @clear="handleSearch" v-model="Active" placeholder="请选择有效性" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="productHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="产品销售经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index==0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index==0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == productHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == productHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="项目销售经手人" name="2" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="项目销售经手人">
                  <el-input v-model="Name" clearable @clear="handleSearch" placeholder="请输入项目销售经手人名称"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select @change="handleSearch" @clear="handleSearch" v-model="Active" placeholder="请选择有效性" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="projectHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="项目销售经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == projectHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == projectHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="通用次卡销售经手人" name="3" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="通用次卡经手人">
                  <el-input @clear="handleSearch" v-model="Name" clearable placeholder="请输通用次卡经手人名称"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select @change="handleSearch" @clear="handleSearch" v-model="Active" placeholder="请选择有效性" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="generalCardHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="通用次卡经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == generalCardHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == generalCardHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="时效卡销售经手人" name="4" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="时效卡销售经手人">
                  <el-input v-model="Name" clearable @clear="handleSearch" placeholder="请输入时效卡经手人名称"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select @change="handleSearch" @clear="handleSearch" v-model="Active" placeholder="请选择有效性" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="timeCardHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="时效卡销售经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == timeCardHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == timeCardHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="储值卡销售经手人" name="5" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="储值卡经手人">
                  <el-input v-model="Name" clearable placeholder="请输入储值卡经手人名称" @clear="handleSearch"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select v-model="Active" placeholder="请选择有效性" clearable @clear="handleSearch" @change="handleSearch">
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="savingCardHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="储值卡经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == savingCardHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == savingCardHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="套餐卡销售经手人" name="6" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="套餐卡经手人">
                  <el-input @clear="handleSearch" v-model="Name" clearable placeholder="请输入套餐卡经手人名称"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select @change="handleSearch" @clear="handleSearch" v-model="Active" placeholder="请选择有效性" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="packageCardHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="套餐卡经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == packageCardHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == packageCardHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="项目消耗经手人" name="7" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="项目消耗经手人">
                  <el-input @clear="handleSearch" v-model="Name" clearable placeholder="请输入项目消耗经手人名称"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select @change="handleSearch" @clear="handleSearch" v-model="Active" placeholder="请选择有效性" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="projectConsumptionHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="项目消耗经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == projectConsumptionHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == projectConsumptionHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="储值卡消耗项目经手人" name="9" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="储值卡消耗经手人">
                  <el-input @clear="handleSearch" v-model="Name" clearable placeholder="请输入储值卡消耗经手人名称"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select @change="handleSearch" @clear="handleSearch" v-model="Active" placeholder="请选择有效性" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="valueCardConsumptionHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="储值卡消耗项目经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == valueCardConsumptionHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == valueCardConsumptionHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="产品消耗经手人" name="8" style="height: 100%">
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                <el-form-item label="产品消耗经手人">
                  <el-input v-model="Name" clearable @clear="handleSearch" placeholder="请输入产品消耗经手人名称"></el-input>
                </el-form-item>
                <el-form-item label="组织单位">
                  <treeselect @input="handleSearch" v-model="EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择组织单位" class="width_220" />
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select @change="handleSearch" @clear="handleSearch" v-model="Active" placeholder="请选择有效性" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" @click="showAddDialog" size="small">新增</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table :data="productConsumptionHandlerList" size="small" tooltip-effect="light">
            <el-table-column prop="Name" label="产品消耗经手人"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button size="small" type="primary" circle icon="el-icon-upload2" @click="upOneClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-top" @click="upClick(scope.row, scope.$index)" :disabled="scope.$index == 0"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-bottom" @click="downClick(scope.row, scope.$index)" :disabled="scope.$index == productConsumptionHandlerList.length-1"></el-button>
                <el-button size="small" type="primary" circle icon="el-icon-download" @click="downOneClick(scope.row, scope.$index)" :disabled="scope.$index == productConsumptionHandlerList.length-1"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="EntityName" label="适用组织"></el-table-column>
            <el-table-column label="适用职务" show-overflow-tooltip min-width="150px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.JobType" :key="index">{{ item.JobTypeName
                  }}{{ scope.row.JobType.length == index + 1 ? "" : "," }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有效性">
              <template slot-scope="scope">{{
                scope.row.Active ? "是" : "否"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="showEditDialog(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--增加、编辑弹出框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="840px">
      <el-tabs v-model="activeName" class="editTabs">
        <el-tab-pane label="基本信息" name="first">
          <el-form :model="ruleFormAdd" :rules="rules_add" ref="ruleFormAdd" label-width="120px" class="demo-ruleForm" size="small">
            <el-form-item label="适用组织" prop="EntityID">
              <span slot="label">
                适用组织
                <el-popover placement="top-start" width="200" trigger="hover">
                  <p>适用于同级所有节点，则只需选择父节点。</p>
                  <p>比如：适用于所有节点，只需选择“顶级/第一个”节点。</p>
                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </span>
              <treeselect style="width: 44%" v-model="ruleFormAdd.EntityID" :options="applyStoreOptions" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="请选择适用组织" />
            </el-form-item>

            <el-form-item label="经手人名称" prop="Name">
              <span slot="label">
                经手人名称
                <el-popover placement="top-start" width="200" trigger="hover">
                  <p>经手人名称显示在“销售/消耗”经手人的选择。</p>
                  <p>比如：适用于所有节点，只需选择“顶级/第一个”节点。</p>
                  <el-button type="text" style="color: #dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </span>
              <el-input style="width: 100%" clearable v-model="ruleFormAdd.Name" placeholder="请输入经手人名称"></el-input>
            </el-form-item>

            <el-form-item label="有效性" prop="Active" v-if="whichDialog == '1'">
              <el-radio-group v-model="ruleFormAdd.Active">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="适用职务" name="second">
          <el-row :gutter="20" class="pad_10_0">
            <el-col :span="10">
              <el-input placeholder="输入职务名称搜索" size="small" v-model="filterJobName" clearable></el-input>
            </el-col>
            <el-col :span="14">
              <el-button type="primary" @click="clickAddApplicableDuty" size="small">配置适用职务
              </el-button>
            </el-col>
          </el-row>
          <el-table size="small" :data="
              JobTypeList.filter(
                (data) =>
                  !filterJobName ||
                  data.JobTypeName.toLowerCase().includes(
                    filterJobName.toLowerCase()
                  )
              )
            " max-height="450">
            <el-table-column label="职务名称" sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.JobTypeName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="职务描述">
              <template slot-scope="scope">
                <span>{{ scope.row.JobDescription }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="deleteSelectedJobType(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- <el-transfer 
          filterable
          filter-placeholder="请输入职务名称"
          :titles = "['全部', '已选']"
          v-model="transferValue" 
          :data="applicableDutyList"
          @change="transferVlaueChange"
          ></el-transfer> -->
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitFormAdd('ruleFormAdd')" :loading="modalLoading">保 存</el-button>
      </span>
    </el-dialog>
    <!--添加适用职务弹出框-->
    <el-dialog :visible.sync="addApplicableDutyDialog" width="700px">
      <div slot="title">
        <span>配置适用职务</span>
      </div>
      <el-row>
        <el-col :span="10" class="pad_10_0">
          <el-input size="small" v-model="JobTypeName" clearable placeholder="输入职务名称搜索"></el-input>
        </el-col>
      </el-row>
      <el-table size="small" :data="
          applicableDutyList.filter(
            (data) =>
              !JobTypeName ||
              data.JobName.toLowerCase().includes(JobTypeName.toLowerCase())
          )
        " max-height="480px" @selection-change="getSelectedJobType" ref="multipleTable">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="JobName" label="职务名称" sortable column-key="JobName"></el-table-column>
        <el-table-column prop="JobDescription" label="职务描述"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addApplicableDutyDialog = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitFormApplicableDuty">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import APIProduct from "@/api/iBeauty/HanderCommission/productSaleHandler";
import APIProject from "@/api/iBeauty/HanderCommission/projectSaleHandler";
import APIGeneralCard from "@/api/iBeauty/HanderCommission/generalCardSaleHandler";
import APISavingCard from "@/api/iBeauty/HanderCommission/savingCardSaleHandler";
import APITimeCard from "@/api/iBeauty/HanderCommission/timeCardSaleHandler";
import APIPackageCard from "@/api/iBeauty/HanderCommission/packageCardSaleHandler";
import APIProjectTreat from "@/api/iBeauty/HanderCommission/projectTreatHandler";
import APIProductTreat from "@/api/iBeauty/HanderCommission/productTreatHandler";
import APICardProject from "@/api/iBeauty/HanderCommission/cardProjectTreatHandler";
var Enumerable = require("linq");
export default {
  components: { Treeselect },
  name: "Handler",
  data() {
    return {
      loading: false, // 加载状态
      modalLoading: false, // 点击状态
      ID: "", // 美容师ID
      currentPage: "1", // 当前页面
      activeName: "first", // 当前标签页
      Name: "", // 经手人名称
      Active: true, // 有效性
      EntityID: null, // 门店ID
      dialogTitle: "", // 增加、编辑弹出框标题
      dialogVisible: false, // 增加、编辑弹出框状态
      filterJobName: "", // 职务搜索框数据
      applyStoreOptions: [], // 适用组织数据
      addApplicableDutyDialog: false, // 适用职务弹出框状态
      JobTypeName: "", // 适用职务搜索框数据
      dutyList: [], // 适用职务数据
      selectedJobTypeList: [], // 选中的适用职务数据
      applicableDutyList: [], // 适用职务弹出框表格数据
      productHandlerList: [], // 产品经手人列表
      projectHandlerList: [], // 项目经手人列表
      generalCardHandlerList: [], // 通用次卡经手人列表
      timeCardHandlerList: [], // 时效卡经手人列表
      savingCardHandlerList: [], // 储值卡经手人列表
      packageCardHandlerList: [], // 套餐卡经手人列表
      projectConsumptionHandlerList: [], // 项目消耗经手人列表
      productConsumptionHandlerList: [], // 产品消耗经手人列表
      valueCardConsumptionHandlerList: [], // 储值卡消耗经手人列表
      // transferValue:[],

      JobTypeList: [],
      whichDialog: "", // 判断为新增还是编辑 0为新增 1为编辑
      ruleFormAdd: {
        EntityID: null, // 适用组织
        Name: "", // 经手人名称
        Active: "", // 有效性
        JobType: [], // 适用职务
      }, // 增加、编辑表单数据
      rules_add: {
        EntityID: [
          { required: true, message: "请选择适用组织", trigger: "change" },
        ],
        Name: [
          { required: true, message: "请输入经手人名称", trigger: "change" },
        ],
        Active: [
          { required: true, message: "请选择有效性", trigger: "change" },
        ],
      }, // 增加、编辑表单验证
    };
  },
  watch: {
    JobTypeName(val) {
      var that = this;
      that.$nextTick(() => {
        that.applicableDutyList.forEach((val) => {
          that.$refs.multipleTable.toggleRowSelection(val, false);
        });
      });
      if (that.selectedJobTypeList.length > 0) {
        that.selectedJobTypeList.forEach((item) => {
          that.applicableDutyList.forEach((val) => {
            if (item.JobTypeID == val.JobTypeID) {
              that.$nextTick(() => {
                that.$refs.multipleTable.toggleRowSelection(val);
              });
            }
          });
        });
      }
    },
  },
  methods: {
    // 搜索
    handleSearch() {
      var that = this;
      switch (that.currentPage) {
        case "1":
          that.getProductSaleHandler();
          break;
        case "2":
          that.getProjectSaleHandler();
          break;
        case "3":
          that.getGeneralCardSaleHandler();
          break;
        case "4":
          that.getTimeCardSaleHandler();
          break;
        case "5":
          that.getSavingCardSaleHandler();
          break;
        case "6":
          that.getPackageCardSaleHandler();
          break;
        case "7":
          that.getProjectTreatHandler();
          break;
        case "8":
          that.getProductTreatHandler();
          break;
        case "9":
          that.getCardProjectTreatHandler();
          break;
      }
    },
    // 侧边栏导航点击事件
    selectCardType() {
      var that = this;
      that.productHandlerList = [];
      that.projectHandlerList = [];
      that.generalCardHandlerList = [];
      that.timeCardHandlerList = [];
      that.savingCardHandlerList = [];
      that.packageCardHandlerList = [];
      that.projectConsumptionHandlerList = [];
      that.productConsumptionHandlerList = [];
      that.valueCardConsumptionHandlerList = [];
      that.Name = "";
      that.Active = true;
      that.EntityID = null;
      that.handleSearch();
    },

    // 获取产品经手人设置列表
    getProductSaleHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APIProduct.getProductSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取项目经手人设置列表
    getProjectSaleHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APIProject.getProjectSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取通用次卡经手人设置列表
    getGeneralCardSaleHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APIGeneralCard.getGeneralCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取储值卡经手人设置列表
    getSavingCardSaleHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APISavingCard.getSavingCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取时效卡经手人设置列表
    getTimeCardSaleHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APITimeCard.getTimeCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.timeCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取套餐卡经手人设置列表
    getPackageCardSaleHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APIPackageCard.getPackageCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取项目消耗经手人设置列表
    getProjectTreatHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APIProjectTreat.getProjectTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectConsumptionHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取产品消耗经手人设置列表
    getProductTreatHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APIProductTreat.getProductTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productConsumptionHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 获取储值卡消耗经手人设置列表
    getCardProjectTreatHandler: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        EntityID: that.EntityID,
      };
      APICardProject.getCardProjectTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.valueCardConsumptionHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 编辑
    showEditDialog(row) {
      var that = this;
      that.ID = row.ID;
      that.ruleFormAdd = Object.assign({}, row);
      that.JobTypeList = Object.assign([], row.JobType);
      that.whichDialog = "1";
      that.activeName = "first";
      that.dialogTitle = "编辑经手人";
      that.filterJobName = "";
      that.dialogVisible = true;
      that.transferValue = [];
      // that.JobTypeList.forEach(item => {
      //   that.transferValue.push(item.JobTypeID)
      // });
    },
    // 新增
    showAddDialog() {
      var that = this;
      that.JobTypeName = "";
      that.JobTypeList = [];
      that.ruleFormAdd = {};
      that.whichDialog = "0";
      that.activeName = "first";
      that.filterJobName = "";
      that.dialogTitle = "新增经手人";
      that.dialogVisible = true;
      // that.transferValue = [];
    },
    // 添加、编辑表单保存事件
    submitFormAdd(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          var defaultCheckedKeys = Enumerable.from(that.JobTypeList)
            .select((val) => val.JobTypeID)
            .toArray();
          that.ruleFormAdd.JobType = defaultCheckedKeys;
          // that.ruleFormAdd.JobType = that.transferValue;
          if (that.whichDialog == "1") {
            if (that.ruleFormAdd.JobType.length > 0) {
              switch (that.currentPage) {
                case "1":
                  that.updateProductSaleHandler();
                  break;
                case "2":
                  that.updateProjectSaleHandler();
                  break;
                case "3":
                  that.updateGeneralCardSaleHandler();
                  break;
                case "4":
                  that.updateTimeCardSaleHandler();
                  break;
                case "5":
                  that.updateSavingCardSaleHandler();
                  break;
                case "6":
                  that.updatePackageCardSaleHandler();
                  break;
                case "7":
                  that.updateProjectTreatHandler();
                  break;
                case "8":
                  that.updateProductTreatHandler();
                  break;
                case "9":
                  that.updateValueCardTreatHandler();
                  break;
              }
            } else {
              that.$message.error("请选择适用职务");
            }
          } else {
            if (that.ruleFormAdd.JobType.length > 0) {
              switch (that.currentPage) {
                case "1":
                  that.createProductSaleHandler();
                  break;
                case "2":
                  that.createProjectSaleHandler();
                  break;
                case "3":
                  that.createGeneralCardSaleHandler();
                  break;
                case "4":
                  that.createTimeCardSaleHandler();
                  break;
                case "5":
                  that.createSavingCardSaleHandler();
                  break;
                case "6":
                  that.createPackageCardSaleHandler();
                  break;
                case "7":
                  that.createProjectTreatHandler();
                  break;
                case "8":
                  that.createProductTreatHandler();
                  break;
                case "9":
                  that.createValueCardTreatHandler();
                  break;
              }
            } else {
              that.$message.error("请选择适用职务");
            }
          }
        }
      });
    },

    // 创建产品经手人设置
    createProductSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      APIProduct.createProductSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProductSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 创建项目经手人设置
    createProjectSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      APIProject.createProjectSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProjectSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 创建通用次卡经手人设置
    createGeneralCardSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      APIGeneralCard.createGeneralCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getGeneralCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 创建储值卡经手人设置
    createSavingCardSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      APISavingCard.createSavingCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getSavingCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 创建时效卡经手人设置
    createTimeCardSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      APITimeCard.createTimeCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getTimeCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 创建套餐卡经手人设置
    createPackageCardSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      APIPackageCard.createPackageCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getPackageCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 创建项目消耗经手人设置
    createProjectTreatHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      APIProjectTreat.createProjectTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProjectTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 创建产品消耗经手人设置
    createProductTreatHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      APIProductTreat.createProductTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProductTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 创建储值卡消耗经手人设置
    createValueCardTreatHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      console.log(params);
      APICardProject.createValueCardHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getCardProjectTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },

    // 更新产品经手人设置
    updateProductSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APIProduct.updateProductSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProductSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 更新项目经手人设置
    updateProjectSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APIProject.updateProjectSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProjectSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 更新通用次卡经手人设置
    updateGeneralCardSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APIGeneralCard.updateGeneralCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getGeneralCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 更新储值卡经手人设置
    updateSavingCardSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APISavingCard.updateSavingCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getSavingCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 更新时效经手人设置
    updateTimeCardSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APITimeCard.updateTimeCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getTimeCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 更新套餐卡经手人设置
    updatePackageCardSaleHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APIPackageCard.updatePackageCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getPackageCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 更新项目消耗经手人设置
    updateProjectTreatHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APIProjectTreat.updateProjectTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProjectTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 更新产品消耗经手人设置
    updateProductTreatHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APIProductTreat.updateProductTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getProductTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },

    // 更新储值卡消耗经手人设置
    updateValueCardTreatHandler: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.ruleFormAdd;
      params.ID = that.ID;
      APICardProject.updateValueCardHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getCardProjectTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },

    // 获取职务列表
    getJobTypeAll: function () {
      var that = this;
      that.loading = true;
      var params = {
        JobTypeName: that.JobTypeName,
      };
      APIProduct.getJobTypeAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            res.Data.forEach((item) => {
              item.JobTypeID = item.ID;
              item.JobTypeName = item.JobName;

              //   that.applicableDutyList.push({
              //     key:item.ID,
              //     label:item.JobName,
              //   })
            });
            that.applicableDutyList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取门店列表
    getEntityList: function () {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
      };
      APIProduct.getEntityList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.applyStoreOptions = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取所选中的职务列表
    getSelectedJobType(list) {
      var that = this;
      that.selectedJobTypeList = list;
    },

    // 移动首部
    upOneClick: function (row) {
      var that = this;
      that.movaClick(row.ID, "");
    },
    // 移动尾部
    downOneClick: function (row, index) {
      var that = this;
      var beforeId = "";
      var tableLength = 0;
      switch (that.currentPage) {
        case "1":
          tableLength = that.productHandlerList.length;
          break;
        case "2":
          tableLength = that.projectHandlerList.length;
          break;
        case "3":
          tableLength = that.generalCardHandlerList.length;
          break;
        case "4":
          tableLength = that.timeCardHandlerList.length;
          break;
        case "5":
          tableLength = that.savingCardHandlerList.length;
          break;
        case "6":
          tableLength = that.packageCardHandlerList.length;
          break;
        case "7":
          tableLength = that.projectConsumptionHandlerList.length;
          break;
        case "8":
          tableLength = that.productConsumptionHandlerList.length;
          break;
        case "9":
          tableLength = that.valueCardConsumptionHandlerList.length;
          break;
      }
      if (index < tableLength - 1) {
        switch (that.currentPage) {
          case "1":
            beforeId = that.productHandlerList[tableLength - 1].ID;
            break;
          case "2":
            beforeId = that.projectHandlerList[tableLength - 1].ID;
            break;
          case "3":
            beforeId = that.generalCardHandlerList[tableLength - 1].ID;
            break;
          case "4":
            beforeId = that.timeCardHandlerList[tableLength - 1].ID;
            break;
          case "5":
            beforeId = that.savingCardHandlerList[tableLength - 1].ID;
            break;
          case "6":
            beforeId = that.packageCardHandlerList[tableLength - 1].ID;
            break;
          case "7":
            beforeId = that.projectConsumptionHandlerList[tableLength - 1].ID;
            break;
          case "8":
            beforeId = that.productConsumptionHandlerList[tableLength - 1].ID;
            break;
          case "9":
            beforeId = that.valueCardConsumptionHandlerList[tableLength - 1].ID;
            break;
        }
      }
      that.movaClick(row.ID, beforeId);
    },
    // 向上
    upClick: function (row, index) {
      var that = this;
      var beforeId = "";
      if (index > 1) {
        switch (that.currentPage) {
          case "1":
            beforeId = that.productHandlerList[index - 2].ID;
            break;
          case "2":
            beforeId = that.projectHandlerList[index - 2].ID;
            break;
          case "3":
            beforeId = that.generalCardHandlerList[index - 2].ID;
            break;
          case "4":
            beforeId = that.timeCardHandlerList[index - 2].ID;
            break;
          case "5":
            beforeId = that.savingCardHandlerList[index - 2].ID;
            break;
          case "6":
            beforeId = that.packageCardHandlerList[index - 2].ID;
            break;
          case "7":
            beforeId = that.projectConsumptionHandlerList[index - 2].ID;
            break;
          case "8":
            beforeId = that.productConsumptionHandlerList[index - 2].ID;
            break;
          case "9":
            beforeId = that.valueCardConsumptionHandlerList[index - 2].ID;
            break;
        }
      }
      that.movaClick(row.ID, beforeId);
    },
    // 向下
    downClick: function (row, index) {
      var that = this;
      var beforeId = "";
      var handlerList = [];
      switch (that.currentPage) {
        case "1":
          handlerList = that.productHandlerList;
          break;
        case "2":
          handlerList = that.projectHandlerList;
          break;
        case "3":
          handlerList = that.generalCardHandlerList;
          break;
        case "4":
          handlerList = that.timeCardHandlerList;
          break;
        case "5":
          handlerList = that.savingCardHandlerList;
          break;
        case "6":
          handlerList = that.packageCardHandlerList;
          break;
        case "7":
          handlerList = that.projectConsumptionHandlerList;
          break;
        case "8":
          handlerList = that.productConsumptionHandlerList;
          break;
        case "9":
          handlerList = that.valueCardConsumptionHandlerList;
          break;
      }
      if (index + 1 != handlerList.length) {
        beforeId = handlerList[index + 1].ID;
      }
      that.movaClick(row.ID, beforeId);
    },
    // 移动顺序
    movaClick: function (moveId, beforeId) {
      var that = this;
      switch (that.currentPage) {
        case "1":
          that.moveProductSaleHandler(moveId, beforeId);
          break;
        case "2":
          that.moveProjectSaleHandler(moveId, beforeId);
          break;
        case "3":
          that.moveGeneralCardSaleHandler(moveId, beforeId);
          break;
        case "4":
          that.moveTimeCardSaleHandler(moveId, beforeId);
          break;
        case "5":
          that.moveSavingCardSaleHandler(moveId, beforeId);
          break;
        case "6":
          that.movePackageCardSaleHandler(moveId, beforeId);
          break;
        case "7":
          that.moveProjectTreatHandler(moveId, beforeId);
          break;
        case "8":
          that.moveProductTreatHandler(moveId, beforeId);
          break;
        case "9":
          that.moveValueCardHandler(moveId, beforeId);
          break;
      }
    },

    // 调整产品经手人设置顺序
    moveProductSaleHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APIProduct.moveProductSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getProductSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 调整项目经手人设置顺序
    moveProjectSaleHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APIProject.moveProjectSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getProjectSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 调整通用次卡经手人设置顺序
    moveGeneralCardSaleHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APIGeneralCard.moveGeneralCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getGeneralCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 调整储值卡经手人设置顺序
    moveSavingCardSaleHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APISavingCard.moveSavingCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getSavingCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 调整时效卡经手人设置顺序
    moveTimeCardSaleHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APITimeCard.moveTimeCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getTimeCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 调整套餐卡经手人设置顺序
    movePackageCardSaleHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APIPackageCard.movePackageCardSaleHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getPackageCardSaleHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 调整项目消耗经手人设置顺序
    moveProjectTreatHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APIProjectTreat.moveProjectTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getProjectTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 调整产品消耗经手人设置顺序
    moveProductTreatHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APIProductTreat.moveProductTreatHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getProductTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 调整储值卡消耗经手人设置顺序
    moveValueCardHandler: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APICardProject.moveValueCardHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getCardProjectTreatHandler();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 添加适用职务点击事件
    clickAddApplicableDuty() {
      var that = this;
      that.JobTypeName = "";
      that.addApplicableDutyDialog = true;
      that.$nextTick(() => {
        that.applicableDutyList.forEach((val) => {
          that.$refs.multipleTable.toggleRowSelection(val, false);
        });
      });
      if (that.JobTypeList.length > 0) {
        var defaultCheckedKeys = Enumerable.from(that.JobTypeList)
          .select((val) => val.JobTypeID)
          .toArray();

        defaultCheckedKeys.forEach((item) => {
          that.applicableDutyList.forEach((val) => {
            if (item == val.JobTypeID) {
              that.$nextTick(() => {
                that.$refs.multipleTable.toggleRowSelection(val);
              });
            }
          });
        });
      }
    },
    // 添加适用职务表单保存事件
    submitFormApplicableDuty() {
      var that = this;
      that.JobTypeList = Object.assign([], that.selectedJobTypeList);
      that.addApplicableDutyDialog = false;
    },
    // 删除所选职务
    deleteSelectedJobType(val) {
      var that = this;
      that.JobTypeList.splice(
        that.JobTypeList.findIndex((p) => p.JobTypeID == val.JobTypeID),
        1
      );
    },
    // 树形选择器数据结构转换
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
    transferVlaueChange: function (events) {
      console.log(events);
    },
  },
  mounted() {
    var that = this;
    that.getProductSaleHandler();
    that.getEntityList();
    that.getJobTypeAll();
  },
};
</script>

<style lang="scss">
.Handler {
  .tab_pane {
    .el-tabs__content {
      height: 100% !important;
      .martp_10 {
        height: calc(100% - 76px);
        overflow-y: auto;
      }
    }
  }
  .editTabs {
    .el-tabs__content {
      overflow: visible !important;
    }
  }
  .el-table {
    .caret-wrapper {
      height: 23px;
      .ascending {
        top: 0px;
      }
      .descending {
        bottom: 1px;
      }
    }
  }
}
</style>
