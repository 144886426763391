/**
 * Created by jerry on 2020/03/30.
 * 通用次卡经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取通用次卡经手人设置列表
    getGeneralCardSaleHandler: params => {
        return API.POST('api/generalCardSaleHandler/all', params)
    },
    // 创建通用次卡经手人设置
    createGeneralCardSaleHandler: params => {
        return API.POST('api/generalCardSaleHandler/create', params)
    },
    // 更新通用次卡经手人设置
    updateGeneralCardSaleHandler: params => {
        return API.POST('api/generalCardSaleHandler/update', params)
    },
    // 调整通用次卡人设置顺序
    moveGeneralCardSaleHandler: params => {
        return API.POST('api/generalCardSaleHandler/move', params)
    },
}
