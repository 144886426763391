
/**
 *  储值卡消耗经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取储值卡经手人设置列表
    getCardProjectTreatHandler: params => {
        return API.POST('api/savingCardProjectTreatHandler/all', params)
    },
    // 创建储值卡经手人设置
    createValueCardHandler: params => {
        return API.POST('api/savingCardProjectTreatHandler/create', params)
    },
    // 更新储值卡经手人设置
    updateValueCardHandler: params => {
        return API.POST('api/savingCardProjectTreatHandler/update', params)
    },
    // 调整储值卡经手人设置顺序
    moveValueCardHandler: params => {
        return API.POST('api/savingCardProjectTreatHandler/move', params)
    },
}