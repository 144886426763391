/**
 * Created by jerry on 2020/03/30.
 * 项目消耗经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取项目消耗经手人设置列表
    getProjectTreatHandler: params => {
        return API.POST('api/projectTreatHandler/all', params)
    },
    // 创建项目消耗经手人设置
    createProjectTreatHandler: params => {
        return API.POST('api/projectTreatHandler/create', params)
    },
    // 更新项目消耗经手人设置
    updateProjectTreatHandler: params => {
        return API.POST('api/projectTreatHandler/update', params)
    },
    // 调整项目消耗经手人设置顺序
    moveProjectTreatHandler: params => {
        return API.POST('api/projectTreatHandler/move', params)
    },
}