/**
 * Created by jerry on 2020/03/30.
 * 套餐卡经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取套餐卡经手人设置列表
    getPackageCardSaleHandler: params => {
        return API.POST('api/packageCardSaleHandler/all', params)
    },
    // 创建套餐卡经手人设置
    createPackageCardSaleHandler: params => {
        return API.POST('api/packageCardSaleHandler/create', params)
    },
    // 更新套餐卡经手人设置
    updatePackageCardSaleHandler: params => {
        return API.POST('api/packageCardSaleHandler/update', params)
    },
    // 调整套餐卡经手人设置顺序
    movePackageCardSaleHandler: params => {
        return API.POST('api/packageCardSaleHandler/move', params)
    },
}